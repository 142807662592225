<template>
  <div>
    <div class="bpr-lastgames" v-if="Object.keys(getLastGame).length > 0">
      <div class="bpr-lastgames__header">Resultados últimos 5 partidos</div>
      <div class="bpr-lastgames__filtercont" :style="cupStyle.visibility">
        <div class="center-item">
          <RadioPlayingAs :is-mobile="isMobileRadio" :section="'resultados ultimos 5 partidos'" />
        </div>
      </div>
      <!-- item game -->
      <div class="igrid-lastgame">
        <div class="igrid-lastgame__header">
          <div class="igrid-lastgame__header__nameteam">{{ selectedGame.homeTeam.colloquial_name }}</div>
          <div :style="cupStyle.header" class="igrid-lastgame__header__position">Posición</div>
          <div :style="cupStyle.header" class="igrid-lastgame__header__points">Puntos</div>
        </div>
        <div :style="cupStyle.content">
          <div class="igrid-lastgame__rowteamsshields">
            <div class="igrid-lastgame__rowteamsshields__contshields">
              <template v-for="(game, i) in getLastGame.home.games">
                <div
                  :key="i"
                  class="igrid-lastgame__rowteamsshields__contshields__itemshiled "
                  :class="{ 'first-element': i == 0 }"
                >
                  <img :src="game.logo" width="24" />
                </div>
              </template>
            </div>
          </div>
          <div class="igrid-lastgame__rowscores">
            <div class="igrid-lastgame__rowscores__team"><img :src="getLastGame.home.logo_home" width="40" /></div>
            <div class="igrid-lastgame__rowscores__contscores">
              <template v-for="(game, i) in getLastGame.home.games">
                <div
                  :key="i"
                  :class="{ 'first-element': i == 0 }"
                  class="igrid-lastgame__rowscores__contscores__itemscore"
                >
                  <img v-if="game.match_result == 'G'" src="/images/winicon.png" width="28" />
                  <img v-if="game.match_result == 'E'" src="/images/drawicon.png" width="28" />
                  <img v-if="game.match_result == 'P'" src="/images/lossicon.png" width="28" />
                </div>
              </template>
            </div>
            <div class="igrid-lastgame__rowscores__stats" :style="cupStyle.header">
              <div class="igrid-lastgame__rowscores__stats__points">{{ getLastGame.home.position }}</div>
              <div class="igrid-lastgame__rowscores__stats__position">{{ getLastGame.home.points }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- item game -->
      <!-- item game -->
      <div class="igrid-lastgame lastitem">
        <div class="igrid-lastgame__header">
          <div v-if="getLastGame" class="igrid-lastgame__header__nameteam">
            {{ selectedGame.awayTeam.colloquial_name }}
          </div>

          <div :style="cupStyle.header" class="igrid-lastgame__header__position">Posición</div>
          <div :style="cupStyle.header" class="igrid-lastgame__header__points">Puntos</div>
        </div>
        <div :style="cupStyle.content">
          <div class="igrid-lastgame__rowteamsshields">
            <div class="igrid-lastgame__rowteamsshields__contshields">
              <template v-for="(game, i) in getLastGame.away.games">
                <div
                  :key="i"
                  class="igrid-lastgame__rowteamsshields__contshields__itemshiled "
                  :class="{ 'first-element': i == 0 }"
                >
                  <img :src="game.logo" width="24" />
                </div>
              </template>
            </div>
          </div>
          <div class="igrid-lastgame__rowscores">
            <div class="igrid-lastgame__rowscores__team">
              <img :src="getLastGame.away.logo_away" width="40" />
            </div>
            <div class="igrid-lastgame__rowscores__contscores">
              <template v-for="(game, i) in getLastGame.away.games">
                <div
                  :key="i"
                  :class="{ 'first-element': i == 0 }"
                  class="igrid-lastgame__rowscores__contscores__itemscore"
                >
                  <img v-if="game.match_result == 'G'" src="/images/winicon.png" width="28" />
                  <img v-if="game.match_result == 'E'" src="/images/drawicon.png" width="28" />
                  <img v-if="game.match_result == 'P'" src="/images/lossicon.png" width="28" />
                </div>
              </template>
            </div>
            <div :style="cupStyle.header" class="igrid-lastgame__rowscores__stats">
              <div class="igrid-lastgame__rowscores__stats__points">{{ getLastGame.away.position }}</div>
              <div class="igrid-lastgame__rowscores__stats__position">{{ getLastGame.away.points }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- item game -->
      <div class="igrid-references">
        <div class="igrid-references__item first-element-refrence">
          <div class="igrid-references__item__icon"><img src="/images/winicon.png" width="16" /></div>
          <div class="igrid-references__item__text">Ganado</div>
        </div>
        <div class="igrid-references__item">
          <div class="igrid-references__item__icon"><img src="/images/drawicon.png" width="16" /></div>
          <div class="igrid-references__item__text">Empatado</div>
        </div>
        <div class="igrid-references__item">
          <div class="igrid-references__item__icon"><img src="/images/lossicon.png" width="16" /></div>
          <div class="igrid-references__item__text">Perdido</div>
        </div>
      </div>
      <LegendLastSeason :mobile="isMobile" :type="3" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: 'BetPerResultLast5Games',
  components: {
    LegendLastSeason: () => import('@/components/Sections/GolTipster-v2/General/LegendLastSeason'),
    RadioPlayingAs: () => import('@/components/Sections/GolTipster-v2/General/RadioPlayingAs'),
  },
  props: {
    gameId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapState(['displayWidth']),
    ...mapState('betsGeneral', ['selectedGame']),
    ...mapGetters('betsGeneral', ['getLastGame']),
    isMobile() {
      return this.displayWidth < 3000;
    },
    isMobileRadio() {
      let value = false;
      if (this.displayWidth < 713) {
        value = true;
      }
      if (this.displayWidth < 563) {
        value = false;
      }
      if (this.displayWidth < 413) {
        value = true;
      }
      return value;
    },
    cupStyle() {
      const cupStyle = {
        header: {},
        content: {},
        visibility: {},
      };
      if (this.selectedGame.isCup) {
        cupStyle.header.display = 'none';
        cupStyle.content.display = 'flex';
        cupStyle.content['flex-direction'] = 'column';
        cupStyle.content['align-items'] = 'center';
        cupStyle.visibility.display = 'none';
      }
      return cupStyle;
    },
  },
  watch: {
    selectedGame: {
      immediate: true,
      async handler(newValue) {
        try {
          if (newValue && newValue.game_id) {
            await this.getLastGamePerGame(newValue.game_id);
          }
        } catch (e) {
          throw new Error(e);
        }
      },
    },
  },
  methods: {
    ...mapActions('betsGeneral', ['getLastGamePerGame']),
  },
};
</script>
<style scoped lang="scss">
.center-item {
  margin: 0 auto;
  height: 40px;
  display: inline-block;
  @media screen and (max-width: 763px) {
    float: left !important;
    margin-left: 10px !important;
  }
  @media screen and (max-width: 713px) {
    display: inline-block !important;
    float: none !important;
    width: 100% !important;
    margin: 0 auto !important;
  }
  @media screen and (max-width: 563px) {
    display: inline-block;
    float: none;
    width: auto !important;
    margin: 0 auto !important;
  }
  @media screen and (max-width: 413px) {
    width: 100% !important;
  }
}
.bpr-lastgames {
  width: 524px;
  height: auto;
  margin: 0 auto;
  @media screen and (max-width: 913px) {
    width: 516px;
  }
  @media screen and (max-width: 863px) {
    width: 466px;
  }
  @media screen and (max-width: 763px) {
    width: 416px;
  }
  @media screen and (max-width: 713px) {
    width: 366px;
  }
  @media screen and (max-width: 613px) {
    width: 316px;
  }
  @media screen and (max-width: 563px) {
    width: 513px;
    margin-top: 40px;
  }
  @media screen and (max-width: 513px) {
    width: 463px;
  }
  @media screen and (max-width: 463px) {
    width: 413px;
  }
  @media screen and (max-width: 413px) {
    width: 363px;
  }
  @media screen and (max-width: 363px) {
    width: 313px;
  }
  @media screen and (max-width: 313px) {
    width: 280px;
  }
  &__header {
    width: 476px;
    height: 28px;
    border-radius: 6px;
    background-color: #132839;
    line-height: 30px;
    font-family: 'Roboto-Medium';
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin: 0 auto;
    @media screen and (max-width: 863px) {
      width: 446px;
    }
    @media screen and (max-width: 763px) {
      width: 396px;
    }
    @media screen and (max-width: 713px) {
      width: 345px;
    }
    @media screen and (max-width: 613px) {
      width: 296px;
    }
    @media screen and (max-width: 563px) {
      width: 476px;
    }
    @media screen and (max-width: 513px) {
      width: 446px;
    }
    @media screen and (max-width: 463px) {
      width: 396px;
    }
    @media screen and (max-width: 413px) {
      width: 345px;
    }
    @media screen and (max-width: 363px) {
      width: 296px;
    }
    @media screen and (max-width: 313px) {
      width: 280px;
    }
  }
  &__subheadertext {
    width: 157px;
    margin: 0 auto;
    height: 15px;
    margin-top: 14px;
    font-family: 'Roboto-Medium';
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 15px;
    letter-spacing: normal;
    text-align: left;
    color: #858f98;
  }

  &__filtercont {
    width: 456px;
    height: 38px;
    margin: 0 auto;
    margin-top: 6px;
    border-radius: 6px;
    @media screen and (max-width: 863px) {
      width: 436px;
    }
    @media screen and (max-width: 763px) {
      width: 386px;
    }
    @media screen and (max-width: 713px) {
      width: 335px;
      height: 90px;
    }
    @media screen and (max-width: 613px) {
      width: 286px;
    }
    @media screen and (max-width: 563px) {
      width: 466px;
    }
    @media screen and (max-width: 513px) {
      width: 436px;
    }
    @media screen and (max-width: 463px) {
      width: 386px;
    }
    @media screen and (max-width: 413px) {
      width: 335px;
    }
    @media screen and (max-width: 363px) {
      width: 286px;
    }
    @media screen and (max-width: 313px) {
      width: 270px;
    }
  }
}
.igrid-footer {
  width: 281px;
  height: 30px;
  margin: 0 auto;
  margin-top: 21px;
  background: #fff;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: center;
  color: #6b6c6c;
  font-family: 'Roboto-Medium';
  &__color {
    font-family: 'Roboto-Black';
    color: #132839;
  }
  @media screen and (max-width: 563px) {
    display: inline-block;
  }
}
.igrid-lastgame {
  width: 476px;
  height: 99px;
  margin: 0 auto;
  margin-top: 26px;
  @media screen and (max-width: 863px) {
    width: 446px;
  }
  @media screen and (max-width: 763px) {
    width: 396px;
  }
  @media screen and (max-width: 713px) {
    width: 345px;
  }
  @media screen and (max-width: 613px) {
    width: 296px;
  }
  @media screen and (max-width: 563px) {
    width: 466px;
  }
  @media screen and (max-width: 513px) {
    width: 446px;
  }
  @media screen and (max-width: 463px) {
    width: 396px;
  }
  @media screen and (max-width: 413px) {
    width: 345px;
  }
  @media screen and (max-width: 363px) {
    width: 296px;
  }
  @media screen and (max-width: 313px) {
    width: 280px;
  }
  &__header {
    width: 100%;
    height: 20px;
    background-color: #e9eef1;
    border-radius: 4px;
    &__nameteam {
      width: auto;
      height: 20px;
      line-height: 20px;
      font-family: 'Roboto-Medium';
      font-size: 13px;
      padding-left: 10px;
      font-weight: 500;
      float: left;
      font-stretch: normal;
      color: #64686e;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
    }
    &__points {
      width: 56px;
      height: 20px;
      line-height: 20px;
      float: right;
      margin-right: 10px;
      color: #64686e;
      font-family: 'Roboto-Medium';
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      @media screen and (max-width: 313px) {
        width: 50px;
        margin-right: 8px;
      }
    }
    &__position {
      width: 56px;
      height: 20px;
      float: right;
      color: #64686e;
      line-height: 20px;
      font-family: 'Roboto-Medium';
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      @media screen and (max-width: 313px) {
        width: 50px;
      }
    }
  }
  &__rowteamsshields {
    //width: 100%;
    height: 37px;
    float: left;
    &__contshields {
      width: 220px;
      height: 24px;
      margin-left: 91px;
      @media screen and (max-width: 763px) {
        margin-left: 66px;
        width: 196px;
      }
      @media screen and (max-width: 713px) {
        margin-left: 5px;
      }
      @media screen and (max-width: 613px) {
        margin-left: 0px;
        width: 164px;
      }
      @media screen and (max-width: 563px) {
        width: 220px;
        margin-left: 91px;
      }
      @media screen and (max-width: 513px) {
        margin-left: 71px;
      }
      @media screen and (max-width: 463px) {
        margin-left: 66px;
        width: 188px;
      }
      @media screen and (max-width: 413px) {
        margin-left: 5px;
        width: 196px;
      }
      @media screen and (max-width: 363px) {
        margin-left: 0px;
        width: 164px;
      }
      margin-top: 13px;
      &__itemshiled {
        width: 36px;
        height: 24px;
        margin-left: 10px;
        float: left;
        @media screen and (max-width: 763px) {
          margin-left: 4px;
        }
        @media screen and (max-width: 613px) {
          margin-left: 1px;
          width: 32px;
        }
        @media screen and (max-width: 563px) {
          margin-left: 10px;
          width: 36px;
        }
        @media screen and (max-width: 463px) {
          margin-left: 2px;
        }
        @media screen and (max-width: 413px) {
          margin-left: 4px;
        }
        @media screen and (max-width: 363px) {
          margin-left: 1px;
          width: 32px;
        }
      }
    }
  }
  &__rowscores {
    //width: 100%;
    height: 42px;
    float: left;
    &__team {
      width: 40px;
      height: 40px;
      float: left;
      @media screen and (max-width: 713px) {
        display: none;
      }
      @media screen and (max-width: 563px) {
        display: inline-block;
      }
      @media screen and (max-width: 413px) {
        display: none;
      }
    }
    &__contscores {
      width: 220px;
      height: 42px;
      margin-left: 51px;
      float: left;
      @media screen and (max-width: 763px) {
        margin-left: 26px;
        width: 196px;
      }
      @media screen and (max-width: 713px) {
        margin-left: 5px;
      }
      @media screen and (max-width: 613px) {
        margin-left: 0px;
        width: 164px;
      }
      @media screen and (max-width: 563px) {
        width: 220px;
        margin-left: 51px;
      }
      @media screen and (max-width: 513px) {
        margin-left: 31px;
      }
      @media screen and (max-width: 463px) {
        margin-left: 26px;
        width: 188px;
      }
      @media screen and (max-width: 413px) {
        margin-left: 5px;
        width: 196px;
      }
      @media screen and (max-width: 363px) {
        margin-left: 0px;
        width: 164px;
      }
      &__itemscore {
        width: 36px;
        height: 42px;
        margin-left: 10px;
        float: left;
        @media screen and (max-width: 763px) {
          margin-left: 4px;
        }
        @media screen and (max-width: 613px) {
          margin-left: 1px;
          width: 32px;
        }
        @media screen and (max-width: 563px) {
          margin-left: 10px;
          width: 36px;
        }
        @media screen and (max-width: 463px) {
          margin-left: 2px;
        }
        @media screen and (max-width: 413px) {
          margin-left: 4px;
        }
        @media screen and (max-width: 363px) {
          margin-left: 1px;
          width: 32px;
        }
        img {
          margin-top: 10px;
        }
      }
    }
    &__stats {
      width: 122px;
      float: left;
      height: 42px;
      margin-left: 43px;
      @media screen and (max-width: 863px) {
        margin-left: 13px;
      }
      @media screen and (max-width: 763px) {
        margin-left: 12px;
      }
      @media screen and (max-width: 713px) {
        margin-left: 22px;
      }
      @media screen and (max-width: 613px) {
        margin-left: 10px;
      }
      @media screen and (max-width: 563px) {
        margin-left: 33px;
      }
      @media screen and (max-width: 463px) {
        margin-left: 20px;
      }
      @media screen and (max-width: 413px) {
        margin-left: 22px;
      }
      @media screen and (max-width: 363px) {
        margin-left: 10px;
      }
      @media screen and (max-width: 313px) {
        margin-left: 8px;
        width: 108px;
      }
      &__points {
        width: 56px;
        height: 42px;
        float: left;
        border-radius: 12px;
        background-color: #f6f6f6;
        font-family: 'Roboto-Bold';
        font-size: 21px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 42px;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        @media screen and (max-width: 313px) {
          width: 50px;
        }
      }
      &__position {
        width: 56px;
        height: 42px;
        float: left;
        margin-left: 10px;
        font-family: 'Roboto-Bold';
        border-radius: 12px;
        background-color: #f6f6f6;
        font-size: 21px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 42px;
        letter-spacing: normal;
        text-align: center;
        color: #000;
        @media screen and (max-width: 313px) {
          margin-left: 8px;
          width: 50px;
        }
      }
    }
  }
}
.lastitem {
  margin-top: 37px;
}
.first-element {
  margin-left: 0px !important;
}
.first-element-refrence {
  margin-left: 8px !important;
  @media screen and (max-width: 363px) {
    margin-left: 3px !important;
  }
  @media screen and (max-width: 313px) {
    margin-left: 0px !important;
  }
}
.igrid-references {
  width: 312px;
  height: 32px;
  margin: 0 auto;
  border-radius: 6px;
  border: solid 2px #d3defb;
  margin-top: 48px;
  @media screen and (max-width: 363px) {
    width: 276px;
  }
  @media screen and (max-width: 313px) {
    width: 265px;
  }
  &__item {
    width: 87px;
    height: 32px;
    float: left;
    margin-left: 16px;
    @media screen and (max-width: 363px) {
      margin-left: 3px;
    }
    @media screen and (max-width: 313px) {
      margin-left: 0px !important;
    }
    &__icon {
      width: 24px;
      float: left;
      height: 32px;
      img {
        margin-top: 6px;
      }
    }
    &__text {
      width: 63px;
      height: 32px;
      font-family: 'Roboto-Medium';
      float: left;
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 30px;
      letter-spacing: normal;
      text-align: center;
      color: #6b6f74;
    }
  }
}
</style>
